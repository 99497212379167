export type StripeAllowedProductDiscount = {
  stripeDiscountId: string;
  name: string;
  type: 'percentage' | 'fixed';
  duration: 'once';
  stripeProducts: string[];
  applyOnSubscriptionCreation: boolean;
  value?: number | null;
};

export type StripeAllowedProductPricing = {
  amount: number;
  discount: number;
  total: number;
  upTo?: number | null;
};

export type SupportedCountry = {
  country: string;
  countryISO2: string;
  countryISO3: string;
  currency: string;
  currencySymbol: string;
  stripeTaxRateId: string;
  signupPackagePrice: number;
  tagPrice: number;
  vat: number;
  isEuCountry: boolean;
};

export type StripeAllowedProduct = {
  stripeProductId: string;
  stripePriceId: string;
  billingInterval: 'year' | 'month';
  name: string;
  description: string;
  discounts: StripeAllowedProductDiscount[];
  visibilityLevel: 'business' | 'internal';
  subscriptionStartingQuantity: number;
  isVolumeBased: boolean;
  taxBehavior: 'inclusive' | 'exclusive';
  defaultStripeDiscountId?: string | null;
  pricing: {
    [currency: string]: StripeAllowedProductPricing[];
  };
};

type GetAllStripeAllowedProductResponse = {
  data: {
    stripeProducts: StripeAllowedProduct[],
    supportedCountries: SupportedCountry[]
  }
};

export const getAllStripeAllowedProduct = async (
  abortController?: AbortController,
): Promise<GetAllStripeAllowedProductResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/billing/v1/stripe/products`,
    {
      method: 'GET',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as GetAllStripeAllowedProductResponse;
};
