import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  BusinessApplication,
  BusinessApplicationBillingInfo,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledButton,
} from './shared';
import {
  StripeAllowedProduct,
  SupportedCountry,
} from '../../../Services/BillingService';
import BusinessBillingSummary from './BusinessBillingSummary';

const setValueOptions = {
  shouldValidate: true,
  shouldTouch: true,
  shouldDirty: true,
};

const isIrishBusiness = (country: string) => ['ireland', 'irl', 'ie'].includes(country?.toLocaleLowerCase());

const filterStripeProducts = (
  stripeProducts: StripeAllowedProduct[],
  businessApplication: BusinessApplication,
  countryData: SupportedCountry | null,
): StripeAllowedProduct[] => {
  if (isIrishBusiness(businessApplication.country?.toLocaleLowerCase())) {
    return stripeProducts.filter(
      (product) => product.taxBehavior === 'exclusive',
    );
  }

  if (businessApplication.billingInformation.businessTaxId || (countryData && !countryData.isEuCountry)) {
    return stripeProducts.filter(
      (product) => product.taxBehavior === 'inclusive',
    );
  }

  return stripeProducts;
};

function BusinessApplicationBillingDetails({
  businessApplication,
  isDataLoading,
  errorOnDataLoading,
  stripeProducts,
  supportedCountries,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  isDataLoading: boolean;
  errorOnDataLoading: boolean;
  stripeProducts: StripeAllowedProduct[];
  supportedCountries: SupportedCountry[];
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);
  const [countryData, setCountryData] = useState<SupportedCountry | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<StripeAllowedProduct | null>(null);
  const [availableProducts, setAvailableProducts] = useState<
  StripeAllowedProduct[]
  >([]);
  const [vatBehavior, setVatBehavior] = useState<'inclusive' | 'all'>('all');
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState,
  } = useForm<BusinessApplicationBillingInfo>({
    disabled: !businessApplication.country || !countryData,
  });

  useEffect(() => {
    setVatBehavior(
      businessApplication.billingInformation.businessTaxId
        && !isIrishBusiness(businessApplication.country)
        ? 'inclusive'
        : 'all',
    );
    const country = supportedCountries?.find((sc) => [sc.country, sc.countryISO2, sc.countryISO3].includes(
      businessApplication.country?.toLowerCase(),
    )) || null;
    const filteredProducts = filterStripeProducts(
      stripeProducts,
      businessApplication,
      country,
    );

    if (businessApplication.billingInformation?.stripePriceId) {
      const stripeProduct = filteredProducts?.find(
        (product) => product.stripePriceId
            === businessApplication.billingInformation?.stripePriceId,
      ) || null;

      setSelectedProduct(stripeProduct);
      setValue(
        'stripePriceId',
        stripeProduct?.stripePriceId || '_',
        setValueOptions,
      );
      setValue(
        'stripeProductId',
        stripeProduct?.stripeProductId || '_',
        setValueOptions,
      );
      setValue(
        'stripeDiscountIds',
        stripeProduct?.defaultStripeDiscountId
          ? [stripeProduct?.defaultStripeDiscountId]
          : ['_'],
        setValueOptions,
      );
      setValue(
        'subscriptionStartingQuantity',
        stripeProduct?.subscriptionStartingQuantity || 1,
        setValueOptions,
      );
    }

    setAvailableProducts(filteredProducts);
    setCountryData(country);
    setValue(
      'stripeCustomerId',
      businessApplication.billingInformation.stripeCustomerId || null,
      setValueOptions,
    );
  }, [stripeProducts?.length, supportedCountries?.length, businessApplication?.country]);

  const onBusinessTaxIdChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!e.target.value && !isIrishBusiness(businessApplication.country)) {
      setVatBehavior('all');

      const currentProduct = stripeProducts.find(
        (product) => product.stripePriceId === selectedProduct?.stripePriceId,
      ) || null;
      setValue(
        'stripePriceId',
        currentProduct?.stripePriceId || '_',
        setValueOptions,
      );
      setValue(
        'stripeProductId',
        currentProduct?.stripeProductId || '_',
        setValueOptions,
      );
      setValue(
        'stripeDiscountIds',
        currentProduct?.defaultStripeDiscountId
          ? [currentProduct?.defaultStripeDiscountId]
          : ['_'],
        setValueOptions,
      );
      setValue(
        'subscriptionStartingQuantity',
        currentProduct?.subscriptionStartingQuantity || 1,
        setValueOptions,
      );
      setAvailableProducts(stripeProducts);
      setSelectedProduct(currentProduct);
    } else if (
      e.target.value
      && vatBehavior === 'all'
      && !isIrishBusiness(businessApplication.country)
    ) {
      const isProductVatInclusive = selectedProduct?.taxBehavior === 'inclusive';
      setVatBehavior('inclusive');
      setAvailableProducts(
        stripeProducts.filter((product) => product.taxBehavior === 'inclusive'),
      );
      setSelectedProduct(isProductVatInclusive ? selectedProduct : null);

      if (!isProductVatInclusive) {
        setValue('subscriptionStartingQuantity', 1, setValueOptions);
        setValue('stripePriceId', '_', setValueOptions);
        setValue('stripeProductId', '_', setValueOptions);
        setValue('stripeDiscountIds', ['_'], setValueOptions);
      }
    }

    setValue('businessTaxId', e.target.value || null);
  };

  const onPriceChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const stripeProduct = availableProducts.find(
      (product) => product.stripePriceId === e.target.value,
    )!;

    setSelectedProduct(stripeProduct);
    setValue(
      'stripeProductId',
      stripeProduct?.stripeProductId,
      setValueOptions,
    );
    setValue('stripePriceId', stripeProduct?.stripePriceId, setValueOptions);
    setValue(
      'billingInterval',
      stripeProduct?.billingInterval,
      setValueOptions,
    );
    setValue(
      'useVolumeBaseBilling',
      Boolean(stripeProduct?.isVolumeBased),
      setValueOptions,
    );
    setValue(
      'subscriptionStartingQuantity',
      stripeProduct?.subscriptionStartingQuantity,
      setValueOptions,
    );
    setValue(
      'stripeDiscountIds',
      stripeProduct?.defaultStripeDiscountId
        ? [stripeProduct?.defaultStripeDiscountId]
        : ['_'],
      setValueOptions,
    );
  };

  const onDiscountChangeHandler = (e: SelectChangeEvent<string[]>) => {
    const currentAppliedDiscounts = new Set<string>(e.target.value);
    const stripeProduct = stripeProducts.find(
      (product) => product.stripeProductId === getValues().stripeProductId,
    );

    const lastSelectedDiscount = (e.target.value.slice(-1) as string[]).pop();
    if (!lastSelectedDiscount) {
      setValue(
        'stripeDiscountIds',
        [
          stripeProduct?.defaultStripeDiscountId
            ? stripeProduct?.defaultStripeDiscountId
            : '_',
        ],
        setValueOptions,
      );
      return;
    }

    if (lastSelectedDiscount === '_') {
      setValue(
        'stripeDiscountIds',
        [
          stripeProduct?.defaultStripeDiscountId
            ? stripeProduct?.defaultStripeDiscountId
            : lastSelectedDiscount,
        ],
        setValueOptions,
      );
      return;
    }

    if (stripeProduct?.defaultStripeDiscountId) {
      currentAppliedDiscounts.add(stripeProduct?.defaultStripeDiscountId);
    }

    currentAppliedDiscounts.delete('_');
    setValue(
      'stripeDiscountIds',
      Array.from(currentAppliedDiscounts),
      setValueOptions,
    );
  };

  const onFormSubmitHandler = useCallback(
    async (data: BusinessApplicationBillingInfo) => {
      try {
        setSubmittingError(false);
        setIsSubmitting(true);
        const result = await updateBusinessApplication({
          ...businessApplication,
          billingInformation: {
            ...data,
          },
        });
        onSetBusinessApplicationToEditHandler(result.data);
      } catch (e) {
        console.log(e);
        setSubmittingError(true);
      } finally {
        setIsSubmitting(false);
      }
    },
    [businessApplication.updatedAt],
  );

  // This is not likely to happen, and probably never will
  // but we all know how good our ppl is to break stuff and trigger edge cases
  // that not even god thought about
  const noCountrySetComponent = useMemo(
    () => (
      <Box
        width="100%"
        height="600px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color="error">
          The business application has no country set or the country set is not supported.
        </Typography>
        <Typography color="error" marginTop="15px">
          Before being able to set the business application billing information
          you have to set a country for the business in the
          {' '}
          <strong>General Details</strong>
          {' '}
          section.
        </Typography>
      </Box>
    ),
    [],
  );

  const loadingComponent = useMemo(
    () => (
      <Box
        width="100%"
        height="600px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    ),
    [],
  );

  const errorComponent = useMemo(
    () => (
      <Box width="100%" textAlign="center">
        <FieldErrorMessage fontSize="1rem">
          It was not possible to fetch the stripe product. Please reach out
          support.
        </FieldErrorMessage>
      </Box>
    ),
    [],
  );

  const contentComponent = (
    <form onSubmit={handleSubmit(onFormSubmitHandler)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4">Basic information</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            {...register('businessTaxId', {
              onChange: onBusinessTaxIdChangeHandler,
            })}
            fullWidth
            defaultValue={businessApplication.billingInformation?.businessTaxId}
            error={!!formState.errors.businessTaxId}
            label="Business Tax ID"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            {...register('registeredBusinessName')}
            fullWidth
            defaultValue={
              businessApplication.billingInformation?.registeredBusinessName
            }
            error={!!formState.errors.registeredBusinessName}
            label="Registered business name"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ margin: '25px 0' }} />
          <Typography variant="h4">Signup package fee discount</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...register('applicationCoupon')}
            fullWidth
            defaultValue={
              businessApplication.billingInformation?.applicationCoupon || '_'
            }
            select
            error={!!formState.errors.applicationCoupon}
            label="Signup fee coupon"
          >
            <MenuItem value="FREESETUP">FREESETUP</MenuItem>
            <MenuItem value="_">NO DISCOUNT</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ margin: '25px 0' }} />
          <Typography variant="h4">Stripe price</Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            name="stripePriceId"
            control={control}
            defaultValue={
              businessApplication.billingInformation.stripePriceId || '_'
            }
            render={({ field }) => (
              <TextField
                {...field}
                onChange={onPriceChangeHandler}
                fullWidth
                select
                label="Stripe price"
              >
                {availableProducts.map((product) => (
                  <MenuItem
                    key={product.stripePriceId}
                    value={product.stripePriceId}
                  >
                    {product.name}
                  </MenuItem>
                ))}
                <MenuItem value="_">No price selected</MenuItem>
              </TextField>
            )}
          />
        </Grid>

        {selectedProduct && (
          <>
            <Grid item xs={12}>
              <Typography variant="h4">
                {selectedProduct.isVolumeBased
                  ? 'Stripe subscription starting MAU'
                  // eslint-disable-next-line max-len
                  : `Stripe subscription's total price - one unit equals to one currency unit (1 = ${countryData?.currencySymbol} 1)`}
              </Typography>
              {selectedProduct.subscriptionStartingQuantity > 1 && (
                <Typography variant="subtitle1">
                  Subscription starting MAU for this product is read-only to
                  ensure that the final price reflects what stated in the squid
                  pricing page
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('subscriptionStartingQuantity', {
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: selectedProduct.isVolumeBased
                      ? 'The subscription must start with at least 1 quantity unit'
                      // eslint-disable-next-line max-len
                      : `You cannot have a flat price subscription with a total price of 0 ${countryData?.currencySymbol}`,
                  },
                })}
                fullWidth
                label={
                  selectedProduct.isVolumeBased
                    ? 'Subscription starting MAU'
                    : `Price payed by the business per location per ${selectedProduct.billingInterval}`
                }
                type="number"
                defaultValue={selectedProduct.subscriptionStartingQuantity}
                inputProps={{
                  readOnly: Boolean(
                    selectedProduct.isVolumeBased
                      && selectedProduct.subscriptionStartingQuantity > 1,
                  ),
                }}
                error={!!formState.errors.subscriptionStartingQuantity}
              />
              {formState.errors.subscriptionStartingQuantity?.message && (
                <FieldErrorMessage>
                  {formState.errors.subscriptionStartingQuantity?.message}
                </FieldErrorMessage>
              )}
            </Grid>
          </>
        )}

        {selectedProduct && selectedProduct.discounts.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h4">Stripe available discounts</Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                onChange={onDiscountChangeHandler}
                fullWidth
                multiple
                value={getValues().stripeDiscountIds || []}
              >
                {selectedProduct.discounts.map((discount) => (
                  <MenuItem
                    key={discount.stripeDiscountId}
                    value={discount.stripeDiscountId}
                  >
                    {discount.name}
                  </MenuItem>
                ))}
                <MenuItem key="_" value="_">
                  No discount
                </MenuItem>
              </Select>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <BusinessBillingSummary
            selectedStripeProduct={selectedProduct}
            selectedStripeDiscounts={watch().stripeDiscountIds || []}
            countryData={countryData}
            subscriptionStartingQuantity={
              watch().subscriptionStartingQuantity || 1
            }
            numberOfLocations={businessApplication.locations.length}
            businessTaxId={watch().businessTaxId || ''}
            applicationCoupon={watch().applicationCoupon || ''}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ margin: '25px 0' }} />
          <Typography variant="h4">Stripe information summary</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          Stripe customer ID
        </Grid>
        <Grid item xs={12} sm={6}>
          {businessApplication.billingInformation?.stripeCustomerId}
        </Grid>

        <Grid item xs={12} sm={6}>
          Stripe product ID
        </Grid>
        <Grid item xs={12} sm={6}>
          {getValues().stripeProductId}
        </Grid>

        <Grid item xs={12} sm={6}>
          Stripe price ID
        </Grid>
        <Grid item xs={12} sm={6}>
          {getValues().stripePriceId}
        </Grid>

        <Grid item xs={12} sm={6}>
          Stripe discount IDs
        </Grid>
        <Grid item xs={12} sm={6}>
          {getValues().stripeDiscountIds?.join(',')}
        </Grid>

        <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
          <StyledButton
            disabled={isSubmitting}
            fullWidth
            type="submit"
            withChildComponent={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size="20px" /> : 'Save changes'}
          </StyledButton>
          {submittingError && (
            <Typography
              variant="body2"
              color="error"
              textAlign="center"
              marginTop="15px"
            >
              Something went wrong while submitting the update, please try again
            </Typography>
          )}
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Billing details</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {(isDataLoading || availableProducts.length === 0)
            && !errorOnDataLoading
            && loadingComponent}
          {!isDataLoading && errorOnDataLoading && errorComponent}
          {!isDataLoading
            && !errorOnDataLoading
            && Boolean(availableProducts.length)
            && countryData
            && contentComponent}
          {!isDataLoading
            && !errorOnDataLoading
            && Boolean(availableProducts.length)
            && !countryData
            && noCountrySetComponent}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationBillingDetails;
