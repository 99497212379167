import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { StripeAllowedProduct, StripeAllowedProductPricing, SupportedCountry } from '../../../Services/BillingService';

const findStripeProductTierByQuantity = (
  productPricing: StripeAllowedProductPricing[],
  quantity: number,
) => productPricing.reduce(
  (result: number | null, current, index) => ((current.upTo && quantity <= current.upTo && result === null)
      || (!current.upTo && result === null)
    ? index
    : result),
  null,
) || 0;

export type ComputedDiscounts = {
  discountsList: {
    name: string;
    applyOnSubscriptionCreation: boolean;
    total: number;
  }[];
  total: number;
};

function BusinessPricingSummaryDiscountsList({
  computedDiscounts,
  currencySymbol,
}: Readonly<{
  computedDiscounts: ComputedDiscounts;
  currencySymbol: string;
}>) {
  if (
    !computedDiscounts?.discountsList
    || computedDiscounts?.discountsList.length === 0
  ) {
    return (
      <Grid key="_discount_list" item xs={12} marginTop="15px">
        <Typography>No discounts selected</Typography>
      </Grid>
    );
  }

  return (
    <>
      {computedDiscounts.discountsList.map((discount) => (
        <React.Fragment key={discount.name}>
          <Grid item xs={12} sm={6}>
            {discount.name}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            {`${currencySymbol} ${discount.total}`}
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
}

const computeDiscounts = (
  stripeProduct: StripeAllowedProduct,
  stripeProductPricing: StripeAllowedProductPricing,
  selectedDiscounts: string[],
): ComputedDiscounts => {
  let amount = stripeProductPricing.total;
  const discountsList = stripeProduct.discounts
    .filter((discount) => selectedDiscounts.includes(discount.stripeDiscountId))
    .map((discount) => {
      const total = discount.applyOnSubscriptionCreation
        ? stripeProductPricing.discount
        : parseFloat((amount * (discount.value || 0)).toFixed(2));

      amount -= discount.applyOnSubscriptionCreation ? 0 : total;
      return {
        name: discount.name,
        applyOnSubscriptionCreation: discount.applyOnSubscriptionCreation,
        total,
      };
    });

  return {
    discountsList,
    total: parseFloat(
      discountsList
        .reduce((result, current) => result + current.total, 0)
        .toFixed(2),
    ),
  };
};

function BusinessBillingSummary({
  selectedStripeProduct,
  selectedStripeDiscounts,
  countryData,
  subscriptionStartingQuantity,
  numberOfLocations,
  businessTaxId,
  applicationCoupon,
}: Readonly<{
  selectedStripeProduct: StripeAllowedProduct | null;
  selectedStripeDiscounts: string[];
  countryData?: SupportedCountry | null;
  subscriptionStartingQuantity: number;
  numberOfLocations: number;
  businessTaxId: string;
  applicationCoupon: string;
}>) {
  if (!selectedStripeProduct || !countryData) {
    return (
      <>
        <Grid item xs={12}>
          <Divider style={{ margin: '25px 0' }} />
          <Typography variant="h4">Business billing summary</Typography>
        </Grid>
        <Grid item xs={12} textAlign="center" marginTop="25px">
          {!selectedStripeProduct
            ? 'No price selected'
            : 'The business application country is not recognized'}
        </Grid>
      </>
    );
  }

  // Only 1 application coupon is available "FREESETUP";
  const hasInclusiveVatBehavior = selectedStripeProduct.taxBehavior === 'inclusive';
  const vatExempt = businessTaxId || hasInclusiveVatBehavior;
  const hasValidApplicationCoupon = applicationCoupon && applicationCoupon !== '_';

  const signupPackagePrice = parseFloat((countryData.signupPackagePrice * numberOfLocations).toFixed(2));
  const signupPackageDiscount = hasValidApplicationCoupon
    ? parseFloat((countryData.signupPackagePrice * numberOfLocations).toFixed(2))
    : 0;
  const signupPackageVat = parseFloat(
    ((signupPackagePrice - signupPackageDiscount) * (businessTaxId ? 0 : countryData.vat)).toFixed(2),
  );
  const signupPackageTotalPrice = parseFloat(
    (signupPackagePrice - signupPackageDiscount + signupPackageVat).toFixed(2),
  );

  const defaultStartingTierIndex = !selectedStripeProduct.isVolumeBased || !subscriptionStartingQuantity
    ? 0
    : findStripeProductTierByQuantity(
      selectedStripeProduct.pricing[countryData.currency],
      subscriptionStartingQuantity,
    );
  const stripeProductPricing = {
    ...selectedStripeProduct.pricing?.[countryData.currency]?.[
      defaultStartingTierIndex
    ],
    total: selectedStripeProduct.isVolumeBased
      ? selectedStripeProduct.pricing?.[countryData.currency]?.[
        defaultStartingTierIndex
      ].total
      : subscriptionStartingQuantity,
  };
  const stripeProductPricePerLocation = selectedStripeProduct.isVolumeBased
    ? stripeProductPricing.amount
    : subscriptionStartingQuantity;
  const computedDiscounts = computeDiscounts(
    selectedStripeProduct,
    stripeProductPricing,
    selectedStripeDiscounts,
  );
  const totalDiscount = parseFloat(
    (computedDiscounts.total * numberOfLocations).toFixed(2),
  );
  const totalAmountWithoutVat = (selectedStripeProduct.isVolumeBased
    ? stripeProductPricing.amount
    : subscriptionStartingQuantity) * numberOfLocations;
  const subtotal = parseFloat(
    (totalAmountWithoutVat - totalDiscount).toFixed(2),
  );
  const vat = !vatExempt ? subtotal * countryData.vat : 0;
  const totalWithVat = parseFloat((subtotal + vat).toFixed(2));

  return (
    <>
      <Grid item xs={12}>
        <Divider style={{ margin: '25px 0' }} />
        <Typography variant="h4">Business billing summary</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems="center"
        style={{ marginTop: '0px' }}
      >
        <Grid item xs={12} sm={6}>
          <Typography>Number of locations</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {numberOfLocations}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Product name</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectedStripeProduct.name}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Product VAT behavior</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${selectedStripeProduct.taxBehavior.toUpperCase()} - ${
            hasInclusiveVatBehavior
              ? 'VAT not added to the final price'
              : 'VAT added to the final price'
          }`}
        </Grid>

        <Grid item xs={12} style={{ margin: '25px 0 ' }}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Signup package price summary</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            Signup package unit price w/o VAT per location
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${countryData.signupPackagePrice}`}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Signup package price total w/o VAT</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${signupPackagePrice}`}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Signup package discount</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${signupPackageDiscount} ${
            applicationCoupon && applicationCoupon !== '_' ? `(${applicationCoupon})` : ''
          } `}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Signup package VAT</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${signupPackageVat} ${
            businessTaxId ? '(VAT exempt)' : ` (${countryData.vat * 100}%)`
          } `}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography fontWeight="bold">Signup package TOTAL</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${signupPackageTotalPrice}`}
        </Grid>

        <Grid item xs={12} style={{ margin: '25px 0 ' }}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Business subscriptions summary</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Is product volume based</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectedStripeProduct.isVolumeBased ? 'Yes' : 'No'}
        </Grid>

        {selectedStripeProduct.isVolumeBased ? (
          <>
            <Grid item xs={12} sm={6}>
              <Typography>Starting tier</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {`Tier ${defaultStartingTierIndex + 1}`}
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} sm={6}>
          <Typography>Amount per location w/o VAT</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${stripeProductPricePerLocation}`}
        </Grid>

        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <Typography variant="h4">Discounts applied per location</Typography>
        </Grid>

        <BusinessPricingSummaryDiscountsList
          computedDiscounts={computedDiscounts}
          currencySymbol={countryData.currencySymbol}
        />

        <Grid item xs={12} style={{ margin: '25px 0 ' }}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography fontWeight="bold">Total amount w/o VAT</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${totalAmountWithoutVat}`}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography fontWeight="bold">Total discount</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${totalDiscount}`}
        </Grid>

        <Grid item xs={12} style={{ margin: '25px 0 ' }}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography fontWeight="bold">Subtotal</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${countryData.currencySymbol} ${subtotal}`}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography fontWeight="bold">VAT</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {businessTaxId && `${countryData.currencySymbol} 0 (VAT exempt)`}
          {!businessTaxId
            && hasInclusiveVatBehavior
            && `${countryData.currencySymbol} 0 (VAT included in price)`}
          {!businessTaxId
            && !hasInclusiveVatBehavior
            && `${countryData.currencySymbol} ${vat.toFixed(2)} (${
              countryData.vat * 100
            }%)`}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography fontWeight="bold">
            {`TOTAL PER ${selectedStripeProduct.billingInterval.toUpperCase()}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight="bold">{`${countryData.currencySymbol} ${totalWithVat}`}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessBillingSummary;
