import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import {
  addTagToBusinessApplication,
  BusinessApplication,
  BusinessApplicationTag,
  BusinessApplicationTagOrderStatus,
  deleteTagFromBusinessApplication,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledBodyCell,
  StyledButton,
  StyledHeaderCell,
  StyledIconButton,
  StyledTableContainer,
} from './shared';
import { requiredFieldErrorMessage } from '../utils';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';

type BusinessApplicationTagData = Pick<
BusinessApplication,
| 'tagOrderStatus'
| 'tagDeliveryAddress'
| 'tagDeliveryToEachLocation'
| 'tagDeliveryTrackingNumber'
>;

type NewTagData = Omit<BusinessApplicationTag, 'tagId'>;

function BusinessApplicationLinkedTags({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<BusinessApplicationTag | null>(
    null,
  );

  const onTagDeleteHandler = async (
    tag: BusinessApplicationTag,
  ): Promise<void> => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await deleteTagFromBusinessApplication(
        businessApplication.businessId,
        tag?.locationId!,
        tag?.schemeId!,
        tag?.tagId!,
        tag?.tagPreEncodeId!,
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
      setSelectedTag(null);
      setIsConfirmDialogOpen(false);
    }
  };

  const tableContent = businessApplication.tags.length > 0 ? (
    businessApplication.tags.map((tag) => (
      <TableRow key={tag.tagId}>
        <StyledBodyCell>{tag.locationId}</StyledBodyCell>
        <StyledBodyCell>{tag.schemeId}</StyledBodyCell>
        <StyledBodyCell>{tag.tagId}</StyledBodyCell>
        <StyledBodyCell>{tag.tagPreEncodeId}</StyledBodyCell>
        <StyledBodyCell>
          <StyledIconButton
            color="primary"
            onClick={() => {
              setSelectedTag(tag);
              setIsConfirmDialogOpen((prev) => !prev);
            }}
            size="small"
          >
            <AiFillDelete />
          </StyledIconButton>
        </StyledBodyCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <StyledBodyCell colSpan={5}>No tags linked</StyledBodyCell>
    </TableRow>
  );

  return (
    <>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledHeaderCell>Location Id</StyledHeaderCell>
              <StyledHeaderCell>Scheme Id</StyledHeaderCell>
              <StyledHeaderCell>Tag Id</StyledHeaderCell>
              <StyledHeaderCell>Tag pre-encode Id</StyledHeaderCell>
              <StyledHeaderCell>Actions</StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>
      </StyledTableContainer>
      {error && (
        <Typography
          variant="body2"
          color="error"
          textAlign="center"
          marginTop="15px"
        >
          It was not possible to delete the tag, please try again.
        </Typography>
      )}
      <ConfirmDialog
        dialogTitle="Confirm your action"
        dialogText="Are you sure you want to delete the linked tag?"
        open={isConfirmDialogOpen}
        isLoading={isLoading}
        handleCancel={() => setIsConfirmDialogOpen((prev) => !prev)}
        handleConfirm={() => onTagDeleteHandler(selectedTag!)}
      />
    </>
  );
}

function BusinessApplicationLinkTagForm({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { register, handleSubmit, formState } = useForm<NewTagData>({
    defaultValues: {
      businessId: businessApplication.businessId,
    },
  });

  const onFormSubmitHandler = async (data: NewTagData) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await addTagToBusinessApplication(
        data.businessId || businessApplication.businessId,
        data.locationId,
        data.schemeId,
        data.tagPreEncodeId,
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmitHandler)}>
      <Divider style={{ margin: '25px 0' }} />
      <Typography variant="h4" marginBottom="25px">
        Link new tag
      </Typography>
      <Grid container alignItems="center" gap="25px">
        <Grid item xs={2}>
          <TextField
            {...register('locationId', { required: requiredFieldErrorMessage })}
            fullWidth
            select
            error={!!formState.errors.businessId}
            label="Location Id"
          >
            {businessApplication.locations.map((location) => (
              <MenuItem key={location.locationId} value={location.locationId}>
                {location.locationId}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            {...register('schemeId', { required: requiredFieldErrorMessage })}
            fullWidth
            select
            error={!!formState.errors.businessId}
            label="Scheme Id"
          >
            {businessApplication.schemes.map((scheme) => (
              <MenuItem key={scheme.schemeId} value={scheme.schemeId}>
                {scheme.schemeId}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            {...register('tagPreEncodeId', {
              valueAsNumber: true,
              required: requiredFieldErrorMessage,
              min: { value: 1, message: 'Value must be greater than 0' },
            })}
            fullWidth
            type="number"
            error={!!formState.errors.businessId}
            label="Tag pre-encode Id"
          />
        </Grid>
        <Grid item>
          <StyledButton
            disabled={isLoading}
            fullWidth
            type="submit"
            withChildComponent={isLoading}
            width="150px"
          >
            {isLoading ? (<CircularProgress size="20px" />) : 'Link tag'}
          </StyledButton>
        </Grid>
      </Grid>
      {error && (
        <Typography
          variant="body2"
          color="error"
          textAlign="center"
          marginTop="15px"
        >
          It was not possible to add the tag, please try again.
        </Typography>
      )}
    </form>
  );
}

function BusinessApplicationTagsDetails({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const {
    register, handleSubmit, getValues, formState,
  } = useForm<BusinessApplicationTagData>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showLinkTagForm, setShowLinkTagForm] = useState(false);

  const onFormSubmitHandler = useCallback(
    async (data: BusinessApplicationTagData) => {
      try {
        setError(false);
        setIsLoading(true);
        const result = await updateBusinessApplication({
          ...businessApplication,
          ...data,
          tagDeliveryToEachLocation: Boolean(data.tagDeliveryToEachLocation),
        });
        onSetBusinessApplicationToEditHandler(result.data);
      } catch (e) {
        console.log(e);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [businessApplication.updatedAt],
  );

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Tags details</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Linked tags</Typography>
              <StyledButton
                width="fit-content"
                onClick={() => setShowLinkTagForm((prev) => !prev)}
              >
                {showLinkTagForm ? 'Close link new tag form' : 'Link new tag'}
              </StyledButton>
            </Grid>

            <Grid item xs={12} sm={12}>
              <BusinessApplicationLinkedTags
                businessApplication={businessApplication}
                onSetBusinessApplicationToEditHandler={
                  onSetBusinessApplicationToEditHandler
                }
              />
              {showLinkTagForm && (
                <BusinessApplicationLinkTagForm
                  businessApplication={businessApplication}
                  onSetBusinessApplicationToEditHandler={
                    onSetBusinessApplicationToEditHandler
                  }
                />
              )}
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onFormSubmitHandler)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Tag order status</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('tagOrderStatus', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  select
                  disabled={
                    businessApplication.tagOrderStatus
                    === BusinessApplicationTagOrderStatus.DELIVERED
                  }
                  defaultValue={businessApplication.tagOrderStatus}
                  error={!!formState.errors.tagOrderStatus}
                  label="Tag order status"
                >
                  <MenuItem
                    value={BusinessApplicationTagOrderStatus.NOT_PURCHASED}
                  >
                    NOT PURCHASED
                  </MenuItem>
                  <MenuItem value={BusinessApplicationTagOrderStatus.PURCHASED}>
                    PURCHASED
                  </MenuItem>
                  <MenuItem value={BusinessApplicationTagOrderStatus.POSTED}>
                    POSTED
                  </MenuItem>
                  <MenuItem
                    value={BusinessApplicationTagOrderStatus.IN_TRANSIT}
                  >
                    IN TRANSIT
                  </MenuItem>
                  <MenuItem
                    value={BusinessApplicationTagOrderStatus.OUT_FOR_DELIVERY}
                  >
                    OUT FOR DELIVERY
                  </MenuItem>
                  <MenuItem value={BusinessApplicationTagOrderStatus.DELIVERED}>
                    DELIVERED
                  </MenuItem>
                </TextField>
                {formState.errors?.tagOrderStatus && (
                  <FieldErrorMessage>
                    {formState.errors?.tagOrderStatus?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Shipping details</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('tagDeliveryAddress')}
                  fullWidth
                  defaultValue={businessApplication.tagDeliveryAddress}
                  error={!!formState.errors.tagDeliveryAddress}
                  label="Tag delivery address"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('tagDeliveryTrackingNumber')}
                  fullWidth
                  defaultValue={businessApplication.tagDeliveryTrackingNumber}
                  error={!!formState.errors.tagDeliveryTrackingNumber}
                  label="Tag delivery tracking number"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('tagDeliveryToEachLocation', {
                    validate: (
                      value: BusinessApplication['tagDeliveryToEachLocation'],
                    ) => {
                      if (!getValues().tagDeliveryAddress) {
                        return true;
                      }

                      return getValues().tagDeliveryAddress && value
                        ? 'You cannot delivery tags to each location if a tag delivery address is specified'
                        : true;
                    },
                  })}
                  fullWidth
                  select
                  defaultValue={
                    businessApplication.tagDeliveryToEachLocation === null
                      ? businessApplication.tagDeliveryToEachLocation
                      : Number(businessApplication.tagDeliveryToEachLocation)
                  }
                  error={!!formState.errors.tagDeliveryToEachLocation}
                  label="Deliver tag to each location address"
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
                {formState.errors?.tagDeliveryToEachLocation && (
                  <FieldErrorMessage>
                    {
                      formState.errors?.tagDeliveryToEachLocation
                        ?.message as string
                    }
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save changes'
                  )}
                </StyledButton>
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    Something went wrong while submitting the update, please try
                    again
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationTagsDetails;
