/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { BusinessApplicationIssuesSummary } from '../../../../Services/BusinessApplicationService';

export function BusinessApplicationIssueSummary({
  businessApplicationIssuesSummary,
}: Readonly<{
  businessApplicationIssuesSummary: BusinessApplicationIssuesSummary;
}>) {
  return (
    <div style={{
      display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', width: '100%',
    }}
    >
      <Typography
        variant="body2"
        color="error"
        textAlign="center"
        marginTop="15px"
        marginBottom="25px"
      >
        Some of the business application information are not present or not correct.
        <br />
        It will not be possible to approve/request changes until there are items listed below to fix.
      </Typography>
      {Object.entries(businessApplicationIssuesSummary.issues)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, issues]) => issues.length > 0)
        .map(([entity, issues]) => (
          <div key={entity} style={{ marginBottom: '25px' }}>
            <Typography variant="body2" color="error">{entity.toUpperCase()}</Typography>
            {issues.map((issue) => (
              <Grid key={issue.entityId} container spacing={2} marginTop="10px">
                <Grid item xs={2}>
                  <Typography variant="body2">Entity ID:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{issue.entityId}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">Error:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{issue.message}</Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        ))}
    </div>
  );
}
