import { useEffect, useState } from 'react';
import { getAllStripeAllowedProduct, StripeAllowedProduct, SupportedCountry } from '../../../Services/BillingService';

const useGetStripeAllowedProducts = (refresh?: boolean) => {
  const [stripeProducts, setStripeProducts] = useState<
  StripeAllowedProduct[]
  >([]);
  const [supportedCountries, setSupportedCountries] = useState<SupportedCountry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const result = await getAllStripeAllowedProduct(
          abortController,
        );
        setStripeProducts(result.data.stripeProducts || []);
        setSupportedCountries(result.data.supportedCountries);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          setError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => abortController?.abort();
  }, [refresh]);

  return {
    stripeProducts,
    supportedCountries,
    isLoading,
    error,
  };
};

export default useGetStripeAllowedProducts;
