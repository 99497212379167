import { TableCell, TableContainer } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: '1px solid',
  maxHeight: '100%',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '100%',
  },
}));

export const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 700,
  textAlign: 'center',
}));

export const StyledBodyCell = styled(
  TableCell,
  {},
)({
  textAlign: 'center',
  fontWeight: 'inherit',
  padding: '10px',
});
