import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Card, CircularProgress, Collapse, Grid, Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  FormProvider, useFieldArray, useForm,
} from 'react-hook-form';
import { BsPlusSquareFill } from 'react-icons/bs';
import {
  BusinessApplication,
  generateBusinessApplicationLoyaltyScheme,
  LoyaltyScheme,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  StyledButton,
  StyledIconButton,
} from './shared';
import BusinessApplicationSchemesCard from './BusinessApplicationSchemesCard';

type FieldArrayScheme = LoyaltyScheme & { id: string };

function BusinessApplicationSchemesDetails({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const formMethods = useForm<BusinessApplication>();
  const { fields, replace, append } = useFieldArray({
    control: formMethods.control,
    name: 'schemes' as unknown as never,
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (businessApplication.schemes.length > 0) {
      replace(businessApplication.schemes);
    }
  }, [businessApplication.updatedAt]);

  const onAddNewSchemeHandler = async () => {
    try {
      setError(false);
      setIsLoading(true);
      // Because schemes are not live yet nor in the actual db
      // we can avoid checking available scheme suffix numbering
      const newScheme = generateBusinessApplicationLoyaltyScheme(
        businessApplication,
        fields.length,
      );
      const result = await updateBusinessApplication(
        { ...businessApplication, schemes: [...businessApplication.schemes, newScheme] },
      );
      onSetBusinessApplicationToEditHandler(result.data);
      append(newScheme);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveSchemeHandler = useCallback(async (schemeIndex: number) => {
    try {
      setError(false);
      setIsLoading(true);
      const updatedFields: FieldArrayScheme[] = [];

      (fields as unknown as FieldArrayScheme[]).forEach((scheme, index) => {
        const currentLocationNumber = parseInt(
          scheme.schemeId.split('_')[1],
          10,
        );

        if (index < schemeIndex || !currentLocationNumber) {
          updatedFields.push(scheme);
          return;
        }

        if (index !== schemeIndex && index > schemeIndex) {
          updatedFields.push({
            ...scheme,
            schemeId: `${scheme.businessId}_${currentLocationNumber - 1}`,
          });
        }
      });
      const result = await updateBusinessApplication(
        { ...businessApplication, schemes: updatedFields },
      );
      onSetBusinessApplicationToEditHandler(result.data);
      replace(updatedFields);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [fields.length, businessApplication.updatedAt]);

  const onFormSubmitHandler = useCallback(async (data: { schemes: LoyaltyScheme[] }) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await updateBusinessApplication(
        { ...businessApplication, schemes: data.schemes },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [businessApplication.updatedAt]);

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Schemes details</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            disabled={!isExpanded || isLoading}
            onClick={() => onAddNewSchemeHandler()}
            size="small"
          >
            <BsPlusSquareFill />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {fields.length === 0 && (
            <Typography variant="h6" textAlign="center">
              No schemes to edit
            </Typography>
          )}
          {fields.length !== 0 && (
            <form onSubmit={formMethods.handleSubmit(onFormSubmitHandler)}>
              <FormProvider {...formMethods}>
                {fields.map((schemeData, schemeIndex) => (
                  <BusinessApplicationSchemesCard
                    key={schemeData.id}
                    schemeData={schemeData as unknown as LoyaltyScheme}
                    businessApplication={businessApplication}
                    schemeIndex={schemeIndex}
                    schemesCount={fields.length}
                    isLoading={isLoading}
                    onRemoveSchemeHandler={onRemoveSchemeHandler}
                    onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
                  />
                ))}
              </FormProvider>
              <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save schemes'
                  )}
                </StyledButton>
                {formMethods.formState.errors?.schemes && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    One of more schemes are missing required fields
                  </Typography>
                )}
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    Something went wrong while submitting the update, please try
                    again or contact support
                  </Typography>
                )}
              </Grid>
            </form>
          )}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationSchemesDetails;
